import React from "react";

export default function GoalTracker() {
    return (
        <div className="goalTrackingPage">
            <div className="content-card-container">
                <h2>Goal Tracker</h2>
                <p></p>
            </div>
        </div>
    )
}