import React from "react";

export default function Marketing() {
    return (
        <div className="marketingPage">
            <div className="content-card-container">
                <h2>Marketing</h2>
                <p>more to come.</p>
            </div>
        </div>
    )
}