import React from "react";

export default function Home() {
    return (
        <div className="homePage">
            <h2>About Me</h2>
            
            {/* <img src={require('../assets/profile.jpg')} style={{width:"100px",height:"100px",borderRadius:'50%'}} alt="Profile" /> */}

            <p>My name is Alex Cook</p>
            <p>digital marketing, fullstack web development, & music.</p>
        </div>
    )
}