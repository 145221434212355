import React from "react";

export default function Resume() {
    return (
        <div className="resumePage">
            <h2>Resume</h2>

            <p>My Resume</p>

        </div>
    )
}